import React from 'react'

import Layout from '../../components/Layout'
import UpdateRollEn from '../../components/UpdateRollEn'
import {Card, CardBody, Col, Row} from "reactstrap";

export default class UpdateEnIndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  updateDimensions() {
    var ua = navigator.userAgent.toLowerCase();
    var isApp = ua.indexOf('anbai') > -1
    let element = document.getElementById('main-wrapper');
    if (isApp) {
      element.classList.add('is-app');
    } else {
      element.classList.remove('is-app');
    }
    // let element = document.getElementById('main-wrapper');
    // const width = window.innerWidth
    // if (width < 1170) {
    //   element.setAttribute('data-sidebartype', 'mini-sidebar');
    //   element.classList.add('mini-sidebar');
    // } else {
    //   element.setAttribute(
    //     'data-sidebartype',
    //     'full'
    //   );
    //   element.classList.remove('mini-sidebar');
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <Layout>
        <div>
          <Row>
            <Col md={{size: 8, offset: 2}}>
              <Card>
                <CardBody>
        <section className="section">
          <div className="container content container-article">
            <div>
              <h1 className="display-7 updateRollTitle">App updates</h1>
            </div>
            <hr />
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="content">
              <UpdateRollEn />
            </div>
          </div>
        </section>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}
